<template>
    <svg>
        <use :xlink:href="`${$contextPath}${sprite}#${name}`"></use>
    </svg>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
    @Prop() name: string;
    @Prop({ default: '/img/sprite.svg' }) source: string;

    get sprite() {
        return (this.$prototype ? '' : '/.resources/gft/webresources') + this.source;
    }
}

</script>
