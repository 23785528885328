<template>
    <div class="mt-10" v-if="filters.length">
        <div :class="{'lg:container': !(filterDesktopFlatList || disableContainer)}">
            <div class="grid relative"
                 :class="[{'text-white' : contrast}, filterDesktopFlatList ? 'md:flex md:flex-wrap md:gap-y-2 md:mb-4' : 'lg:px-10 lg:-mx-10 lg:z-10 lg:gap-x-4 ' + (((filters.length + (showDateFilter ? 1 : 0)) === 2 ? 'lg:grid-cols-2 ' : 'lg:grid-cols-3 ') + containerClasses)]">
                <!-- dataFilter-->
                <dropdown v-if="showDateFilter"
                          ref="dateFilter"
                          :title="dateLabel"
                          :choose-label="chooseLabel"
                          :default-value="0"
                          :default-title="allLabel"
                          :label="dateLabel"
                          :contrast="contrast"
                          @deselect="clearDateFilter"
                          @select="setDateFilter">
                    <dropdown-item :label="allLabel" :value="0" :active="dateFilter === 0" />
                    <dropdown-item :label="pastLabel" :value="-1" :active="dateFilter === -1"
                                   :visible="isAvailable('past')" :disabled="!hasItems('past')" />
                    <dropdown-item :label="upcomingLabel" :value="1" :active="dateFilter === 1"
                                   :visible="isAvailable('upcoming')" :disabled="!hasItems('upcoming')" />
                </dropdown>
                <!-- other filters -->
                <dropdown v-for="filter in filters"
                          :key="'filter-dropdown-'+filter.name"
                          ref="dropdowns"
                          :label="filter.name"
                          :defaultKey="filter.filterKey"
                          :defaultValue="filter.selectedCategory"
                          :defaultTitle="filter.selectedCategory"
                          :choose-label="chooseLabel"
                          :contrast="contrast"
                          :class="{'md:hidden' : filterDesktopFlatList }"
                          @select="toggleCategory"
                          @deselect="val => toggleCategory(val, '', true)">
                    <dropdown-item v-for="cat in filter.categories"
                                   :key="'dropdown-'+cat.categoryId"
                                   :visible="isAvailable(cat.categoryKey)"
                                   :label="cat.name"
                                   cancelable
                                   :active="isSelected(cat.categoryKey)"
                                   :disabled="!hasItems(cat.categoryKey)"
                                   :value="cat.categoryKey" />
                </dropdown>
                <!-- only for filterDesktopFlatList -->
                <template v-if="filterDesktopFlatList">
                    <div
                        class="hidden lg:flex cursor-pointer items-center"
                        @click="removeAllCat()"
                        :class="categoriesNames.length ? 'text-gray-600' : 'text-current' ">
                        <icon name="gft-filter-icon" class="h-4 w-4 stroke-current fill-current" />
                        <div class="text-xl ml-4 mr-10">{{ allResults }}</div>
                    </div>
                    <template v-for="filter in filters">
                        <div v-for="cat in filter.categories.filter(x => isAvailable(x.categoryKey))"
                             :key="'list-'+cat.categoryId"
                             @click="toggleCategory(cat.categoryKey)"
                             class="hidden pr-10 text-xl items-center md:flex"
                             :class="[
                                hasItems(cat.categoryKey)
                                    ? {
                                        'md:flex cursor-pointer': true,
                                        'text-white': contrast && isSelected(cat.categoryKey),
                                        'text-gray-600 hover:text-gray-400': !isSelected(cat.categoryKey)
                                    }
                                    : 'text-gray-600 opacity-50 pointer-events-none'
                            ]">
                            <icon v-if="isSelected(cat.categoryKey)" name="close"
                                  class="w-6 h-6 m-0 fill-current" />
                            <div class="whitespace-nowrap my-1 leading-5"
                                 :class="{ 'pl-1': filter.selectedCategory === cat.categoryId }">{{ cat.name }}
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </div>
        <!-- only for dropdown desktop -->
        <div class="block relative -mt-4 mb-20" :class="{ 'lg:hidden': filterDesktopFlatList }">
            <div class="absolute w-full lg:border-b"></div>
        </div>
        <!-- list of active filters-->
        <div v-if="filterDesktopFlatList && showListActiveFilters && categoriesNames.length"
             :class="{'text-white': contrast}" class="hidden md:flex mb-8">
            <div class="text-sm uppercase pr-2 my-1 leading-5">{{ resultsFor }}</div>
            <div v-for="category in categoriesNames" :key="`fdl-${category.key}`"
                 class="flex ml-4 items-center cursor-pointer"
                 @click="removeCat(category)">
                <icon name="close" class="w-5 h-5 m-0 fill-current" />
                <div class="pl-1 my-1 leading-5">{{ category.name }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Inject, Prop, Ref, Watch } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';
import Dropdown from '../Dropdown.vue';
import AsyncOverviewFilter from './AsyncOverviewFilter.vue';

@Component({
    components: { Icon, Dropdown }
})
export default class OverviewFilters extends Vue {
    @Prop({ default: false }) contrast!: boolean;
    @Prop({ default: false }) isEditMode: boolean;
    @Prop({ default: '' }) containerClasses: string;
    @Prop({ default: 'All' }) allResults: string;
    @Prop({ default: () => [] }) filters: InstanceType<typeof AsyncOverviewFilter>[];
    @Prop({ default: false }) disableContainer: boolean;
    // for flat style
    @Prop({ default: false }) filterDesktopFlatList: boolean;
    @Prop({ default: false }) showListActiveFilters: boolean;
    @Prop({ default: 'Results for' }) resultsFor: string;
    // for dropdowns
    @Ref('dropdowns') filtersDropdowns: Dropdown[];
    @Prop({ required: true }) chooseLabel: string;
    // for data filter
    @Ref('dateFilter') dateFilterDropdown: Dropdown;
    @Prop({ default: false }) showDateFilter: boolean;
    @Prop({ default: '' }) allLabel: string;
    @Prop({ default: '' }) dateLabel: string;
    @Prop({ default: '' }) pastLabel: string;
    @Prop({ default: '' }) upcomingLabel: string;
    @Prop({ default: 0 }) dateFilter: number;

    @Inject({ default: () => () => undefined }) toggleCategory: (categoryKey: string, parent?: string, remove?: boolean) => void;
    @Inject({ default: () => () => false }) isSelected: (categoryKey: string) => boolean;
    @Inject({
        default: () => ({
            categoriesUrl: '',
            selectedFilters: [[]]
        })
    }) reactiveOverviewVars: {
        categoriesUrl: string;
        selectedCategories: string[];
    };

    loading = false;
    controller = new AbortController();
    // available categories with the current filter selection (others will be deselected)
    availableCategories: { [id: string]: number } = {};
    // all available categories (others will be hidden)
    allAvailableCategories: { [id: string]: number } = {};

    mounted() {
        window.addEventListener('hashchange', this.changedHash);
    }

    /**
     * filter by dates
     * @param value if negative, shows past items, positive shows future items. 0 applies no date filter
     */
    setDateFilter(value: number) {
        this.$emit('date-filter', value);
    }

    clearDateFilter() {
        this.$emit('clear-date-filter');
    }

    // remove category selected at filter
    removeCat(category) {
        this.filters.forEach(filter => {
            if (filter.filterKey === category.parentKey) {
                this.toggleCategory(category.id);
            }
        });
    }

    // remove all categories selected at filter
    removeAllCat() {
        this.categoriesNames.forEach(category => {
            this.filters.forEach(filter => {
                if (filter.filterKey === category.parentKey) {
                    this.toggleCategory(category.key);
                }
            });
        });
        this.filtersDropdowns.forEach(dropdown => {
            dropdown.resetDropdown();
        });
    }

    isAvailable(categoryKey: string) {
        if (!Object.keys(this.allAvailableCategories).length) {
            return true;
        }
        if (Object.prototype.hasOwnProperty.call(this.allAvailableCategories, categoryKey)) {
            return this.allAvailableCategories[categoryKey] > 0;
        }
        return false;
    }

    // used to disable filters if the lead to an empty result
    hasItems(categoryKey: string): boolean {
        // currently selected filter is always enabled (in order to deselect it)
        if (!Object.keys(this.availableCategories).length || this.reactiveOverviewVars.selectedCategories.includes(categoryKey)) {
            return true;
        }
        if (Object.prototype.hasOwnProperty.call(this.availableCategories, categoryKey)) {
            // enable filter if it leads to at least 1 result
            return this.availableCategories[categoryKey] > 0;
        }
        return false;
    }

    // get all selected categories' name and parentKey
    get categoriesNames(): { key: string; name: string; parentKey: string }[] {
        return this.filters.reduce((categoriesNames, filter) => {
            return categoriesNames.concat(
                filter.categories
                    .filter(category => this.reactiveOverviewVars.selectedCategories.includes(category.categoryKey))
                    .map(category => ({
                        key: category.categoryKey,
                        name: category.name,
                        parentKey: filter.filterKey
                    }))
            );
        }, []);
    }

    // when the selected filters change fetch the available categories again
    @Watch('reactiveOverviewVars', { deep: true })
    async getAvailableCategories() {
        if (this.loading) {
            this.controller.abort();
            this.loading = false;
            this.controller = new AbortController();
        }
        try {
            this.loading = true;
            const categoriesResponse = await fetch(this.reactiveOverviewVars.categoriesUrl, { signal: this.controller.signal });
            if (categoriesResponse.status === 200) {
                this.availableCategories = await categoriesResponse.json();
            }
            const allCategoriesResponse = await fetch(this.reactiveOverviewVars.categoriesUrl.replace(/\?.*$/, ''), { signal: this.controller.signal });
            if (allCategoriesResponse.status === 200) {
                this.allAvailableCategories = await allCategoriesResponse.json();
            }
            this.loading = false;
        } catch (_) {
            this.loading = false;
        }
    }

    // used when the hash change without refresh the page (changed by navigation)
    changedHash() {
        this.removeAllCat();
        const hash = location.hash.replace(/^#/, '');
        const filters = hash
            .split('&')
            .filter(x => x.includes(':'))
            .map(x => x.split(':'))
            .filter(x => x.length === 2);

        filters.forEach(filter => {
            this.filtersDropdowns.forEach(dropdown => {
                if (filter[0] === dropdown.defaultKey) {
                    dropdown.setValue(filter[1], true);
                }
            });
        });
    }
}
</script>
